<template>
	<template v-if="!wrap">
		<div v-if="label" class="form-label">
			<label ref="labelRef" :class="labelClassName" :for="uid">
				{{ label }}
			</label>
		</div>
		<input
			:id="uid"
			v-bind="$attrs"
			ref="inputRef"
			v-mdb-click-outside="clickOutside"
			:class="inputClassName"
			:value="inputValue"
			@input="handleInput"
			@focus="handleFocus"
			@blur="handleBlur"
		/>
		<div v-if="helper" class="form-helper">{{ helper }}</div>
		<div v-if="counter" class="form-helper">
			<div class="form-counter">{{ currentLength }} / {{ maxlength }}</div>
		</div>
		<slot />
		<div v-if="validFeedback" :class="validFeedbackClassName">
			{{ validFeedback }}
		</div>
		<div v-if="customInvalidFeedback" :class="invalidFeedbackClassName">
			{{ customInvalidFeedback }}
		</div>
		<div v-if="!formOutline" ref="notchRef" class="form-notch">
			<div
				class="form-notch-leading"
				:style="{ width: `${notchLeadingWidth}px` }"
			></div>
			<div
				class="form-notch-middle"
				:style="{ width: `${notchMiddleWidth}px` }"
			></div>
			<div class="form-notch-trailing"></div>
		</div>
	</template>
	<component
		:is="tag"
		v-if="wrap"
		v-mdb-click-outside="clickOutside"
		:class="wrapperClassName"
		:style="validationStyle"
	>
		<slot name="prepend" />
		<div v-if="label" class="form-label">
			<label ref="labelRef" :class="labelClassName" :for="uid">
				{{ label }}
			</label>
		</div>
		<input
			v-bind="$attrs"
			:id="uid"
			ref="inputRef"
			:class="inputClassName"
			:value="inputValue"
			:type="options.toggleVisibility && !visible ? 'password' : 'text'"
			@input="handleInput"
			@focus="handleFocus"
			@blur="handleBlur"
		/>
		<div v-if="helper" class="form-helper">{{ helper }}</div>
		<div v-if="counter" class="form-helper">
			<div class="form-counter">{{ currentLength }} / {{ maxlength }}</div>
		</div>
		<slot></slot>
		<div v-if="validFeedback" :class="validFeedbackClassName">
			{{ validFeedback }}
		</div>
		<div v-if="customInvalidFeedback" :class="invalidFeedbackClassName">
			{{ customInvalidFeedback }}
		</div>
		<div v-if="formOutline" ref="notchRef" class="form-notch">
			<div
				class="form-notch-leading"
				:style="{ width: `${notchLeadingWidth}px` }"
			></div>
			<div
				class="form-notch-middle"
				:style="{ width: `${notchMiddleWidth}px` }"
			></div>
			<div class="form-notch-trailing"></div>
		</div>
	</component>
	<div v-if="formText" class="form-text">{{ formText }}</div>

	<div v-if="type === 'number'" class="arrows">
		<IconBadge
			:options="{ classList: ['cursor-pointer'] }"
			icon="caret-up"
			@click="arrowClick(parseInt(inputValue), 1)"
		/>
		<IconBadge
			:options="{ classList: ['cursor-pointer'] }"
			icon="caret-down"
			@click="arrowClick(parseInt(inputValue), -1)"
		/>
	</div>
	<div
		v-if="options.toggleVisibility"
		class="trailing"
		@click="visible = !visible"
	>
		<IconBadge v-if="visible" icon="far.eye" />
		<IconBadge v-else icon="far.eye-slash" />
	</div>
</template>

<script setup lang="ts">
import {
	computed,
	ref,
	onMounted,
	onUpdated,
	watchEffect,
	onUnmounted,
	watch,
	useAttrs,
} from "vue"
import IconBadge from "@/Components/IconBadge.vue"

import { on, off } from "./MDBEventHandlers"
import { getUID } from "./getUID"
import vMdbClickOutside from "./mdbClickOutside"

const props = defineProps({
	id: { type: String, default: undefined },
	label: { type: String, default: undefined },
	labelClass: { type: String, default: undefined },
	modelValue: { type: [String, Number, Date], default: undefined },
	size: { type: String, default: undefined },
	formOutline: {
		type: Boolean,
		default: true,
	},
	wrapperClass: { type: String, default: undefined },
	inputGroup: {
		type: [Boolean, String],
		default: false,
	},
	wrap: {
		type: Boolean,
		default: true,
	},
	formText: { type: String, default: undefined },
	white: { type: Boolean },
	validationEvent: { type: String, default: undefined },
	isValidated: { type: Boolean },
	isValid: { type: Boolean },
	validFeedback: { type: String, default: undefined },
	invalidFeedback: { type: String, default: undefined },
	tooltipFeedback: {
		type: Boolean,
		default: false,
	},
	tag: {
		type: String,
		default: "div",
	},
	helper: { type: String, default: undefined },
	counter: { type: Boolean },
	maxlength: {
		type: Number,
		default: 0,
	},
	type: {
		type: String,
		default: "text",
	},
	options: {
		type: Object,
		default: () => ({}),
	},
})

const emit = defineEmits(["update:modelValue", "click-outside", "on-validate"])

const attrs = useAttrs()

const visible = ref(false)
const inputRef = ref<HTMLInputElement | null>(null)
const inputValue = ref<any>(props.modelValue)
const labelRef = ref<HTMLLabelElement | null>(null)
const showPlaceholder = ref(false)
const notchLeadingWidth = ref(9)
const notchMiddleWidth = ref(0)
const uid = props.id || getUID("MDBInput-")
const isFocused = ref(false)

const wrapperClassName = computed(() => {
	return [
		props.formOutline && "form-outline",
		inputGroupClassName.value,
		props.white && "form-white",
		props.wrapperClass,
	]
})
const inputClassName = computed(() => {
	return [
		"form-control",
		props.size
			? `form-control-${props.size}`
			: props.inputGroup &&
				props.inputGroup !== true &&
				`form-control-${props.inputGroup}`,
		(inputValue.value || inputValue.value === 0) && "active",
		showPlaceholder.value && "placeholder-active",
		isInputValidated.value && isInputValid.value && "is-valid",
		isInputValidated.value && !isInputValid.value && "is-invalid",
		isSpecialInput.value && getSpecialInputOpacityClass(),
	]
})

const getSpecialInputOpacityClass = () => {
	if (!isSpecialInput.value) return ""

	const shouldBeVisible =
		inputValue.value || inputValue.value === 0 || isFocused.value
	return shouldBeVisible ? "opacity-1" : "opacity-0"
}

const labelClassName = computed(() => {
	return ["form-label", props.labelClass]
})

const inputGroupClassName = computed(() => {
	if (!props.inputGroup) {
		return
	}
	return props.inputGroup !== true
		? `input-group input-group-${props.inputGroup}`
		: "input-group"
})

const validationStyle = computed(() => {
	return props.inputGroup && isInputValidated.value
		? { marginBottom: "1rem" }
		: ""
})

const validFeedbackClassName = computed(() => {
	return props.tooltipFeedback ? "valid-tooltip" : "valid-feedback"
})

const invalidFeedbackClassName = computed(() => {
	return props.tooltipFeedback ? "invalid-tooltip" : "invalid-feedback"
})

// Validation ------------------------
const isInputValidated = ref(props.isValidated)
const isInputValid = ref(props.isValid)
const defaultValidatorInvalidFeedback = ref("")
const customInvalidFeedback = computed(() => {
	if (isInputValidated.value && !isInputValid.value && props.validationEvent) {
		return defaultValidatorInvalidFeedback.value
	}
	return props.invalidFeedback
})

const handleValidation = (e: Event) => {
	const target = e.target as HTMLInputElement
	isInputValid.value = target.checkValidity()
	if (!isInputValid.value) {
		defaultValidatorInvalidFeedback.value = target.validationMessage
	}
	isInputValidated.value = true
	emit("on-validate", isInputValid.value)
}

const bindValidationEvents = () => {
	if (props.validationEvent === "submit") {
		return
	}

	inputRef.value &&
		props.validationEvent &&
		on(inputRef.value, props.validationEvent, handleValidation)
}

function calcNotch() {
	if (labelRef.value) {
		notchMiddleWidth.value = labelRef.value.clientWidth * 0.8 + 8
	}
}

const shouldRecalcNotch = computed(() => {
	// notchMiddle.width is 8px when the label width equal 0 - value 8 is beeing added to the notchMiddleWidth in the calcNotch method
	return (
		props.label &&
		labelRef.value &&
		notchMiddleWidth.value === 8 &&
		labelRef.value.textContent !== ""
	)
})

const isSpecialInput = computed(() => {
	const inputsWithPlaceholder = [
		"date",
		"time",
		"datetime-local",
		"month",
		"week",
	]
	return inputsWithPlaceholder.includes(attrs.type as string)
})

function setPlaceholder() {
	if (attrs.placeholder && !labelRef.value) {
		showPlaceholder.value = true
	} else {
		showPlaceholder.value = false
	}
}

const currentLength = ref<number | null>(null)

currentLength.value =
	typeof inputValue.value === "string" && inputValue.value
		? inputValue.value.length
		: 0

const arrowClick = (val, change) => {
	const finalValue = isNaN(val)
		? change < 0
			? (props.options.minValue ?? 0)
			: (props.options.minValue ?? 0) + 1
		: val + change
	if (
		finalValue < (props.options.minValue ?? 0) ||
		(props.options.maxValue && finalValue > props.options.maxValue)
	)
		return
	inputValue.value = finalValue
	emit("update:modelValue", inputValue.value)
}
function handleInput(e: Event) {
	const target = e.target as HTMLInputElement
	if (
		props.maxlength &&
		target.value.length > props.maxlength &&
		typeof inputValue.value === "string"
	) {
		target.value = inputValue.value
		return
	}
	if (
		props.type === "number" &&
		(parseFloat(target.value) < props.options.minValue ||
			parseFloat(target.value) > props.options.maxValue ||
			isNaN(target.value))
	) {
		target.value = inputValue.value
		return
	}

	currentLength.value = target.value.length
	inputValue.value = target.value
	emit("update:modelValue", inputValue.value)
}

function handleFocus() {
	checkDateType(true)
	isFocused.value = true

	if (props.label && shouldRecalcNotch.value) {
		calcNotch()
	}
}

function handleBlur() {
	checkDateType()
	isFocused.value = false
}

function clickOutside() {
	emit("click-outside")
}

const notchRef = ref<HTMLElement | null>(null)
const inputNotchElements = ref<NodeListOf<HTMLInputElement> | null>(null)

const handleMultipleNotchesVisibility = (isFocused: boolean) => {
	inputNotchElements.value &&
		inputNotchElements.value.forEach(
			(notch) => (notch.style.opacity = isFocused ? "0" : "1"),
		)
	if (isFocused && notchRef.value) {
		notchRef.value.style.opacity = "1"
	}
}

const isTypeDate = attrs.type && attrs.type === "date"
const checkDateType = (isFocused = false) => {
	if (
		props.label &&
		props.formOutline &&
		inputNotchElements.value &&
		inputNotchElements.value?.length > 1
	) {
		handleMultipleNotchesVisibility(isFocused)
	}

	if (!isTypeDate) {
		return
	}

	if (inputRef.value) {
		inputRef.value.type = isFocused ? "date" : "text"
	}
}

const isFirstChild = (element: HTMLElement) => {
	return ![...(element.parentNode as HTMLElement).children].findIndex(
		(item) => item === element,
	)
}

onMounted(() => {
	calcNotch()
	setPlaceholder()
	checkDateType()

	if (props.label && props.formOutline && inputRef.value) {
		inputNotchElements.value =
			inputRef.value.parentNode?.querySelectorAll(".form-notch") || null
	}

	if (
		props.label &&
		props.formOutline &&
		labelRef.value &&
		inputRef.value &&
		!isFirstChild(inputRef.value)
	) {
		const labelLeft = parseFloat(getComputedStyle(labelRef.value).left)
		labelRef.value.style.left = `${labelLeft + inputRef.value.offsetLeft}px`
		notchLeadingWidth.value += inputRef.value.offsetLeft
	}

	if (props.validationEvent) {
		bindValidationEvents()
	}
})

onUpdated(() => {
	calcNotch()
	setPlaceholder()
})

onUnmounted(() => {
	inputRef.value &&
		props.validationEvent &&
		off(inputRef.value, props.validationEvent, handleValidation)
})

watchEffect(() => {
	if (typeof props.modelValue === "string") {
		if (props.maxlength && props.modelValue?.length > props.maxlength) {
			inputValue.value = props.modelValue.slice(0, props.maxlength)
			currentLength.value = props.maxlength
			return
		}

		currentLength.value = props.modelValue?.length || 0
	}

	inputValue.value = props.modelValue
})

watch(
	() => props.isValidated,
	(value) => (isInputValidated.value = value),
)

watch(
	() => props.isValid,
	(value) => (isInputValid.value = value),
)

defineExpose({
	inputRef,
})
</script>

<script lang="ts">
export default {
	name: "MDBInputZ",
	inheritAttrs: false,
}
</script>
<style scoped>
input[type="number"] {
	-webkit-appearance: textfield;
	-moz-appearance: textfield;
	appearance: textfield;
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
	-webkit-appearance: none;
}
.arrows {
	position: absolute;
	transform: translateY(-50%);
	top: 50%;
	right: 20px;
	height: 50%;
	z-index: 1;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
}
.arrows > * {
	justify-self: center;
	align-self: center;
}
.trailing {
	color: rgba(0, 0, 0, 0.6);
	position: absolute;
	top: 30px;
	transform: translateY(calc(-50% + 0.125rem));
	right: 10px;
	opacity: 0.65;
}
</style>
